'use client'
import { useMemo } from 'react'
import ErrorBoundary from '@error-boundary'
import dynamic from 'next/dynamic'

import { RaButton, RaIcon } from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'
import { useCart } from '@contexts/cart.context'
import { useSettings } from '@contexts/settings.context'
import { useUi } from '@contexts/ui.context'
import {
  AdditionalServiceItemProps,
  BuildingBlockPostType,
  isTypeAdditionalService,
  RoundtripPostType,
} from '@typings'
import { buildingBlockOrRoundtrip } from '@utilities/helpers/building-block-or-roundtrip.helpers'
import { translate } from '@utilities/helpers/core.helpers'
import { makeCartItem } from '@utilities/helpers/make-cart-item.helpers'

const RaAddToCartModal = dynamic(() =>
  import('@components/features/RaAddToCartModal/RaAddToCartModal').then(
    ({ RaAddToCartModal }) => RaAddToCartModal
  )
)

interface RaButtonAddToCart {
  item: BuildingBlockPostType | RoundtripPostType | AdditionalServiceItemProps
  variant?: 'icon' | 'text'
  fullWidth?: boolean
}

export const RaButtonAddToCart = ({
  item,
  variant,
  fullWidth = false,
}: RaButtonAddToCart) => {
  const ui = useUi()
  const cart = useCart()
  const { destination } = useSettings()

  const subProducts = useMemo(() => {
    if (isTypeAdditionalService(item)) return null
    const bbOrRt = buildingBlockOrRoundtrip(item)
    const subProducts = (item as any)?.acf?.[
      `${bbOrRt}_additional_services_repeater`
    ]
    if (!subProducts || subProducts.length === 0) return null
    return subProducts
  }, [item])

  const addItemToCart = () => {
    // If only 1 variant, no extra services, add straight to cart
    if (isTypeAdditionalService(item) || !subProducts) {
      cart.addToCart([makeCartItem(destination, item)])
    } else {
      // If additional services, show a modal for the user to choose a variation
      ui.showModal({
        title: translate('ADDITIONAL_SERVICES_DIALOG_BB_TITLE'),
        content: (
          <RaAddToCartModal
            item={item}
            subproducts={subProducts}
          />
        ),
      })
    }
  }

  // If we're on a root domain (no destination), do not show the cart button
  if (!cart?.enableCart) return null

  const itemType = makeCartItem(destination, item).type
  let label = ''
  switch (itemType) {
    case 'rundreise':
      label = translate('REQUEST_ROUNTRIP')
      break

    case 'reise-baustein':
      label = translate('REQUEST_BUILDINGBLOCK')
      break

    case 'variant':
    case 'additional_service':
      label = translate('REQUEST_ADDITIONAL_SERVICE')

      break
  }

  return (
    <ErrorBoundary>
      <RaButton
        small={variant === 'icon'}
        label={variant === 'icon' ? '' : label}
        onClick={addItemToCart}
        variant="primary"
        endIcon={
          <RaIcon
            icon={ICONS.SUITCASE_CONTAINED}
            color="light"
            badge="+"
            badgeTextColor="var(--color-body-dark)"
            badgeColor="#FFC700"
          />
        }
      />
    </ErrorBoundary>
  )
}
